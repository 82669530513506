var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"form-col-label mb-1 control-label required"},[_vm._v(" "+_vm._s(_vm.$t("monitoringQuestion.answer"))+" ")]),_c('div',{staticClass:"answer-container ts-overflow-x-hidden"},[_c('ts-panel-wrapper',{staticStyle:{"background-color":"#f2f3f4"}},[_vm._l((_vm.answerChunks),function(chunk,index){return _c('div',{key:index,staticClass:"row"},_vm._l((chunk),function(answer,key){return _c('div',{key:key,staticClass:"col-sm-12 col-lg-6 col-md-6 mt-3 text-blue mb-3"},[_c('fieldset',[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"ts-space-x-2"},[_c('legend',{staticClass:"ts-inline-block ts-font-bold"},[_vm._v(" "+_vm._s(answer.answer_prefix)+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(answer.answer_en),expression:"answer.answer_en"}],staticClass:"form-control mb-1",attrs:{"type":"text","placeholder":_vm.$t('monitoringQuestion.answerEn')},domProps:{"value":(answer.answer_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(answer, "answer_en", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(answer.answer_kh),expression:"answer.answer_kh"}],staticClass:"form-control mb-1",attrs:{"type":"text","placeholder":_vm.$t('monitoringQuestion.answerKh')},domProps:{"value":(answer.answer_kh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(answer, "answer_kh", $event.target.value)}}}),(
                                    _vm.errors.has(
                                        'answer_lists.' +
                                            (answer.id - 1) +
                                            '.answer_en'
                                    )
                                )?_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.errors.first( "answer_lists." + (answer.id - 1) + ".answer_en" ))+" ")]):_vm._e(),(
                                    _vm.errors.has(
                                        'answer_lists.' +
                                            (answer.id - 1) +
                                            '.answer_kh'
                                    )
                                )?_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.errors.first( "answer_lists." + (answer.id - 1) + ".answer_kh" ))+" ")]):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('ts-checkbox',{staticClass:"text-gray-700",model:{value:(answer.is_enable_comment_box),callback:function ($$v) {_vm.$set(answer, "is_enable_comment_box", $$v)},expression:"answer.is_enable_comment_box"}},[_vm._v(_vm._s(_vm.$t( "monitoringQuestion.isEnableCommentBox" )))])],1),(answer.id > _vm.min_answer_choice)?_c('div',{staticClass:"mt-3"},[_c('a',{staticClass:"ts-font-bold text-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeAnswer(answer)}}},[_vm._v(" "+_vm._s(_vm.$t("monitoringQuestion.remove"))+" ")])]):_vm._e()])])])}),0)}),(_vm.model.answer_lists.length <= 25)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 my-3"},[_c('a',{staticClass:"ts-font-bold text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addAnswer.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("monitoringQuestion.addNewChoice"))+" ")])])]):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }