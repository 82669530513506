<template>
    <div>
        <!-- Answer Section -->
        <label class="form-col-label mb-1 control-label required">
            {{ $t("monitoringQuestion.answer") }}
        </label>
        <div class="answer-container ts-overflow-x-hidden">
            <ts-panel-wrapper style="background-color: #f2f3f4;">
                <div
                    class="row"
                    v-for="(chunk, index) in answerChunks"
                    :key="index"
                >
                    <!-- Loop for each answer chunk -->
                    <div
                        class="col-sm-12 col-lg-6 col-md-6 mt-3 text-blue mb-3"
                        v-for="(answer, key) in chunk"
                        :key="key"
                    >
                        <fieldset>
                            <div class="form-group">
                                <!-- Display prefix like A, B, C -->
                                <div class="ts-space-x-2">
                                    <legend
                                        class="ts-inline-block ts-font-bold"
                                    >
                                        {{ answer.answer_prefix }}
                                    </legend>
                                </div>

                                <!-- English Answer Input -->
                                <input
                                    type="text"
                                    v-model="answer.answer_en"
                                    class="form-control mb-1"
                                    :placeholder="
                                        $t('monitoringQuestion.answerEn')
                                    "
                                />

                                <!-- Khmer Answer Input -->
                                <input
                                    type="text"
                                    v-model="answer.answer_kh"
                                    class="form-control mb-1"
                                    :placeholder="
                                        $t('monitoringQuestion.answerKh')
                                    "
                                />
                                <!-- Error Display -->
                                <div
                                    class="validate"
                                    v-if="
                                        errors.has(
                                            'answer_lists.' +
                                                (answer.id - 1) +
                                                '.answer_en'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            "answer_lists." +
                                                (answer.id - 1) +
                                                ".answer_en"
                                        )
                                    }}
                                </div>
                                <div
                                    class="validate"
                                    v-if="
                                        errors.has(
                                            'answer_lists.' +
                                                (answer.id - 1) +
                                                '.answer_kh'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            "answer_lists." +
                                                (answer.id - 1) +
                                                ".answer_kh"
                                        )
                                    }}
                                </div>
                                <div class="mt-3">
                                    <ts-checkbox
                                        v-model="answer.is_enable_comment_box"
                                        class="text-gray-700"
                                        >{{
                                            $t(
                                                "monitoringQuestion.isEnableCommentBox"
                                            )
                                        }}</ts-checkbox
                                    >
                                </div>

                                <!-- Remove Answer Button -->
                                <div
                                    class="mt-3"
                                    v-if="answer.id > min_answer_choice"
                                >
                                    <a
                                        href="#"
                                        class="ts-font-bold text-danger"
                                        @click.prevent="removeAnswer(answer)"
                                    >
                                        {{ $t("monitoringQuestion.remove") }}
                                    </a>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row" v-if="model.answer_lists.length <= 25">
                    <div class="col-lg-12 my-3">
                        <a
                            href="#"
                            class="ts-font-bold text-primary"
                            @click.prevent="addAnswer"
                        >
                            <i class="fas fa-plus"></i>
                            {{ $t("monitoringQuestion.addNewChoice") }}
                        </a>
                    </div>
                </div>
            </ts-panel-wrapper>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { chunk, clone } from "lodash";

export default {
    name: "multipleChoiceRadio",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {},
            selected: null,
            min_answer_choice: 2,
            answer_prefix: [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z"
            ],
            new_answer: {
                question_id: 0,
                answer_id: 0,
                answer_kh: "",
                answer_en: "",
                answer_prefix: "",
                is_enable_comment_box: false
            }
        };
    },
    created() {
        this.model = this.value;
        this.defaultAnswer();
    },
    computed: {
        answerChunks() {
            return chunk(Object.values(this.model.answer_lists), 2);
        }
    },
    methods: {
        defaultAnswer() {
            this.model.answer_lists = [];
            for (let i = 1; i <= this.min_answer_choice; i++) {
                this.model.answer_lists.push(
                    Object.assign({}, this.new_answer, {
                        id: i,
                        answer_prefix: this.answer_prefix[i - 1]
                    })
                );
            }
        },
        addAnswer() {
            this.model.answer_lists.push(clone(this.new_answer));
            this.model.answer_lists.map((item, index) => {
                item.id = index + 1;
                item.answer_prefix = this.answer_prefix[index];
            });
        },
        removeAnswer(answer) {
            const index = this.model.answer_lists.indexOf(answer);
            if (index !== -1) {
                this.model.answer_lists.splice(index, 1);
            }
        }
    }
};
</script>

<style scoped>
.ts-font-bold {
    font-weight: 900;
}
.answer-container {
    background-color: #f2f3f4;
}
</style>
